import { Injectable, inject } from '@angular/core';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { AuthTokens, updatePassword, type UpdatePasswordInput } from 'aws-amplify/auth';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

import { IdpUserRole, PortalRole } from '../model/auth.model';

@Injectable({
    providedIn: 'root',
})
/**
 * service to get information about the logged in user
 * extract info from token, provided by amplify
 * should only be used by the auth store effects
 */
export class AuthorizeService {
    private amplifyAuthService = inject(AuthenticatorService);

    /**
     * inform amplify to logout the user if the amplify.status is not unauthenticated
     */
    logoutUser(): void {
        if (this.amplifyAuthService.authStatus === 'unauthenticated') {
            return;
        }
        this.amplifyAuthService.signOut();
    }

    /**
     * force refresh the tokens
     */
    refreshToken(): void {
        this.readTokensFromSession(true);
    }

    /**
     * return the access token
     * @returns access toke nor null as promise
     */
    getAccessToken(): Promise<string | null> {
        return this.readTokensFromSession(false).then((tokens) => {
            return tokens?.accessToken.toString() || null;
        });
    }

    /**
     * Extract the fullname, email, token and role from the tokens and return them, the role will be converted from IdpRole to PortalRole
     * @returns the information as promise
     */
    getUserDetailsFromToken(): Promise<{ vorname: string; nachname: string; email: string; token: string; role: PortalRole | null, anrede: string }> {
        return this.readTokensFromSession(false).then((tokens) => {
            const email = tokens?.idToken?.payload['email']?.toString() || '';
            const vorname = tokens?.idToken?.payload['given_name']?.toString() || '';
            const nachname = tokens?.idToken?.payload['family_name']?.toString() || '';
            const token = tokens?.accessToken.toString() || '';
            const role = this.getPortalRoleFromToken(tokens);
            const anrede = tokens?.idToken?.payload['gender']?.toString() || '';
            if (email === '' || token === '') {
                throw new Error("Can't read all login details.");
            }
            return { vorname, nachname, email, token, role, anrede };
        });
    }

    updatePassword({ oldPassword, newPassword }: UpdatePasswordInput): Promise<void> {
        return updatePassword({ oldPassword, newPassword });
    }

    private readTokensFromSession(refresh: boolean): Promise<AuthTokens | null | undefined> {
        return cognitoUserPoolsTokenProvider.getTokens({ forceRefresh: refresh }).then((tokens) => {
            return tokens;
        });
    }

    private getPortalRoleFromToken(token: AuthTokens | null | undefined): PortalRole | null {
        const role = this.extractRoleFromToken(token);
        if (!role) {
            return null;
        }
        if (role !== IdpUserRole.COMPANY_USER) {
            return this.mapIdpToPortalRole(role);
        }
        return PortalRole.COMPANY_SELECT;
    }

    private extractRoleFromToken(token: AuthTokens | null | undefined): IdpUserRole | null {
        const roles = token?.accessToken.payload['cognito:groups'] as Array<string>;
        if (roles && roles.length > 0) {
            return roles[0] as IdpUserRole;
        }
        return null;
    }

    private mapIdpToPortalRole(idpRole: IdpUserRole): PortalRole {
        switch (idpRole) {
            case IdpUserRole.FFA_USER:
                return PortalRole.FFA_USER;
            case IdpUserRole.FFA_TEAMLEADER:
                return PortalRole.FFA_TEAMLEADER;
            case IdpUserRole.FFA_ADMIN:
                return PortalRole.FFA_ADMIN;
            default:
                return PortalRole.COMPANY_SELECT;
        }
    }
}
