import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PortalRole } from '../../model/auth.model';
import { UnternehmenSelectionItem } from '../../model/nutzer-unternehmen-selection.model';

/**
 * contains actions to get informations from authorize service and set them in the auth store.
 * Do not dispatch actions beginning with 'set', these are only used by the service.
 */
export const authActions = createActionGroup({
    source: 'Authentication',
    events: {
        // read user info from tokens and triggers changeLoginDetails to store the info. trigger logout if no info are found
        updateLoginDetails: props<{ isLogin: boolean }>(),
        // stores the login info and  trigger redirect for ffa user or trigger updateNuterUnternehmenSelectionList for company user
        changeLoginDetails: props<{ anrede: string, vorname: string; nachname: string; email: string; token: string; role: PortalRole | null }>(),

        // stores the login info and  trigger redirect for ffa user or trigger updateNuterUnternehmenSelectionList for company user
        changeLoginDetailsAndCompanyNoRedirect: props<{ anrede: string, vorname: string; nachname: string; email: string; token: string; role: PortalRole | null, newlySelectedUnternehmen: UnternehmenSelectionItem }>(),
        // updateLoginDetails with a selected company
        updateLoginDetailsAndCompany: props<{ newlySelectedUnternehmen: UnternehmenSelectionItem }>(),
        // change the role in the store and trigger redirect
        changeUserRole: props<{ role: PortalRole | null }>(),
        // take the new access token from the tokens and store them with setTokenAction action
        updateToken: emptyProps(),
        setToken: props<{ token: string | null }>(),
        // make a force refresh of the tokens
        updateRefreshToken: emptyProps(),
        // triggers the logout in the authorize service and reset the auth store state with setUserLoggedOutAction action
        changeUserToLoggedOut: emptyProps(),
        postChangeUserToLoggedOut: emptyProps(),

        //triggers the passwordChange
        updatePasswordChange: props<{ oldPassword: string, newPassword: string }>(),
    },
});
