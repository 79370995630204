import { FormFileType } from "./aws-service.model";

export type FormularStore = {
    formularUrl: string;
    document: File | null;
    partsMax: number;
    partsUploaded: number;
    showProgressBar: boolean;
    activeAntragsnummer: string;
    formContent: FormField[];
    loadOnlyStammdaten: boolean;
    isFormularInitialized: boolean;
    isNewUnternehmen: boolean;
    isLoadingAFormular: boolean;
    numberOfClosedFileUploads: number;
    groupFileUploadsFailed: boolean;
}

export type FormField = {
    id: string;
    value: string;
    type: string;
    readonly: boolean;
    hId: string;
    hGroupId: string;
}


export enum PayloadType {
    FileUpload = "fileUpload",
    SaveJSON = "saveJSON",
    Script = "script",
    Antragsnummer = "antragsnummer",
    ImportJSON = "importJSON",
    FormularListenersReady = "formularListenersReady",
    TriggerJSONUpload = "triggerJSONUpload"
}

// Payload Formular --> FE

export type FileUploadPayload = {
    type: PayloadType.FileUpload;
    fileData: File;
    formFileType: FormFileType;
    htmlFieldId: string;
}

export type SaveJSONPayload = {
    type: PayloadType.SaveJSON;
    fileData: string;
    formFileType: FormFileType;
    htmlFieldId: string;
    status: string;
}


export type FormularInitializedPayload = {
    type: PayloadType.FormularListenersReady;
}

export type TriggerJSONUploadPayload = {
    type: PayloadType.TriggerJSONUpload;
    filesToUpload: number;
}

export type PayloadFromFormular = FileUploadPayload | SaveJSONPayload | FormularInitializedPayload | TriggerJSONUploadPayload;

// Payload FE --> Formular

export type ScriptPayload = {
    type: PayloadType.Script;
    content: string;
}

export type AntragsnummerPayload = {
    type: PayloadType.Antragsnummer;
    content: string;
}

export type ImportJSONPayload = {
    type: PayloadType.ImportJSON;
    content: FormField[];
}

export type PayloadFromFrontend = ScriptPayload | AntragsnummerPayload | ImportJSONPayload;

export enum AntragsType {
    Foerderantrag,
    Nebenantrag
}
