<!-- TODO: FFA-220 (next where we will work on this): Move to dialog after ticket FFA-167 is done (There the Content option will be added to the dialog) -->
<div *ngIf="showProgressBar$ | async">
    <app-upload-progress [title]="progressBarTitle"></app-upload-progress>
</div>

<iframe
    class="formular-iframe"
    #iframeformular
    [src]="formularLink | safeFormularUrl"
    title="Formular"></iframe>
