import { Injectable, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarComponent } from "../ui/snack-bar/snack-bar.component";
import { LogoutSnackBarComponent } from "../ui/snack-bar/logout-snack-bar/logout-snack-bar.component";

@Injectable({ providedIn: 'root' })
export class SnackBarService {
    private _snackBar = inject(MatSnackBar);
    private snackBarPanelDefaultClasses = ['snack-bar-panel-default'];

    openErrorSnackBar(): void {
        this._snackBar.openFromComponent(SnackBarComponent, { panelClass: [...this.snackBarPanelDefaultClasses, 'snack-bar-panel-error'] });
    }
    openSuccessSnackBar(): void {
        this._snackBar.openFromComponent(SnackBarComponent, { panelClass: [...this.snackBarPanelDefaultClasses, 'snack-bar-panel-success'], duration: 5000 });
    }
    openUnauthorizedSnackBar(): void {
        this._snackBar.openFromComponent(LogoutSnackBarComponent, { panelClass: ['snack-bar-panel-unauthorized'] });
    }
}