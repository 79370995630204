import { createReducer, on } from "@ngrx/store";
import { FormularStore } from "../../model/formular.model";
import { formularActionsExt, formularActionsInt } from "./formular.actions";
import { environment } from '../../../environments/environment';
import { authActions } from "../auth/auth.actions";
import { STAMMDATEN } from "../../model/foerderantraege.model";

export const formularStoreInitialState: FormularStore = {
    formularUrl: '',
    document: null,
    partsMax: 0,
    partsUploaded: 0,
    showProgressBar: false,
    loadOnlyStammdaten: false,
    activeAntragsnummer: "",
    formContent: [],
    isFormularInitialized: false,
    isNewUnternehmen: false,
    isLoadingAFormular: false,
    numberOfClosedFileUploads: 0,
    groupFileUploadsFailed: false,
}

const createFormularUrl = (shortName: string): string => {
    const formularUrl = environment.FMS.origin + environment.FMS.formularServerPath + '/findform?'
        + 'areashortname=' + environment.FMS.areaShortname
        + '&formtecid=' + environment.FMS.formTecId
        + '&shortname=' + shortName;
    return formularUrl;
}
/**
 * The reducer for the form store
 */
export const formularReducer = createReducer(
    formularStoreInitialState,
    on(formularActionsInt.postChangeCreateNewFormular, (state): FormularStore => {
        return { ...state, activeAntragsnummer: "", formularUrl: "", isNewUnternehmen: false, isLoadingAFormular: true };
    }),
    on(formularActionsInt.setUpNewFormular, (state, action): FormularStore => {
        const formularUrl = createFormularUrl(action.shortName);
        return { ...state, formularUrl, activeAntragsnummer: "", loadOnlyStammdaten: true, isNewUnternehmen: false };
    }),
    on(formularActionsInt.postChangeExistingFormular, (state): FormularStore => {
        return { ...state, activeAntragsnummer: "", formularUrl: "", isNewUnternehmen: false, isLoadingAFormular: true };
    }),
    on(formularActionsInt.setExistingFormular, (state, action): FormularStore => {
        const formularUrl = createFormularUrl(action.formularShortname);
        return {
            ...state,
            formularUrl,
            activeAntragsnummer: action.antragsnummer,
            loadOnlyStammdaten: false,
            isLoadingAFormular: false,
        };
    }),
    on(formularActionsInt.setActiveAntragsnummer, (state, action): FormularStore => (
        { ...state, activeAntragsnummer: action.antragsnummer, isLoadingAFormular: false }
    )),
    on(formularActionsInt.resetFormularState, (): FormularStore => (
        { ...formularStoreInitialState }
    )),
    on(formularActionsInt.changeFormContent, (state): FormularStore => (
        { ...state, showProgressBar: true, partsUploaded: 1, partsMax: 2 }
    )),
    on(formularActionsInt.setFormContent, (state, action): FormularStore => (
        { ...state, formContent: action.formContent, partsUploaded: 0, partsMax: 0, showProgressBar: false }
    )),
    on(formularActionsInt.changeFormContentFailure, (state): FormularStore => (
        { ...state, partsUploaded: 0, partsMax: 0, showProgressBar: false }
    )),
    on(formularActionsInt.changeCreateUnternehmen, (state): FormularStore => ({ ...state, isLoadingAFormular: true })),
    on(formularActionsInt.changeUnternehmenStammdaten, (state): FormularStore => ({ ...state, isLoadingAFormular: true })),
    on(formularActionsInt.changeCreateUnternehmenSuccess, (state, action): FormularStore => {
        const formularUrl = createFormularUrl(STAMMDATEN.gatewayShortname);
        return { ...state, formularUrl, activeAntragsnummer: action.antragsnummer, isNewUnternehmen: true, isLoadingAFormular: false }
    }),
    on(formularActionsInt.setListenersReady, (state): FormularStore => (
        { ...state, isFormularInitialized: true }
    )),
    on(formularActionsExt.changeInitializationFileUpload, (state, action): FormularStore => (
        { ...state, document: action.file, showProgressBar: true }
    )),
    on(formularActionsExt.changeGeneratePresignedUrlsUpload, (state, action): FormularStore => (
        { ...state, partsMax: action.partsMax }
    )),
    on(formularActionsExt.setFinializeUpload, (state): FormularStore => (
        { ...state, partsUploaded: 0, partsMax: 0, showProgressBar: false, numberOfClosedFileUploads: state.numberOfClosedFileUploads + 1 }
    )),
    on(formularActionsExt.incrementUploadedParts, (state): FormularStore => (
        { ...state, partsUploaded: state.partsUploaded + 1 }
    )),
    on(formularActionsExt.setFinializeUploadFailure, (state): FormularStore => (
        { ...state, partsUploaded: 0, partsMax: 0, showProgressBar: false, numberOfClosedFileUploads: state.numberOfClosedFileUploads + 1, groupFileUploadsFailed: true }
    )),
    on(formularActionsExt.setGeneratePresignedUrlsFailure, (state): FormularStore => (
        { ...state, partsUploaded: 0, partsMax: 0, showProgressBar: false, numberOfClosedFileUploads: state.numberOfClosedFileUploads + 1, groupFileUploadsFailed: true }
    )),
    on(formularActionsExt.setGeneratePresignedUrlsUploadFailure, (state): FormularStore => (
        { ...state, partsUploaded: 0, partsMax: 0, showProgressBar: false, numberOfClosedFileUploads: state.numberOfClosedFileUploads + 1, groupFileUploadsFailed: true }
    )),
    on(formularActionsExt.setInitializationFileUploadFailure, (state): FormularStore => (
        { ...state, partsUploaded: 0, partsMax: 0, showProgressBar: false, numberOfClosedFileUploads: state.numberOfClosedFileUploads + 1, groupFileUploadsFailed: true }
    )),
    on(formularActionsExt.resetNumberOfClosedFileUploads, (state): FormularStore => ({ ...state, numberOfClosedFileUploads: 0, groupFileUploadsFailed: false })),
    on(authActions.changeUserToLoggedOut, (): FormularStore => ({ ...formularStoreInitialState })),
)
