import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AllUnternehmenNutzer, AllUnternehmenNutzerPage } from '../../model/all-unternehmen-nutzer.model';
import { SortParameter } from '../../model/store.model';
import { TableAction } from '../../model/data-table.model';
import { Foerderbereich } from '../../model/foerderbereich.model';
import { Foerderantrag } from '../../model/foerderantraege.model';
import { NutzerDto } from '../../service/api/generated';
import { PortalRole } from '../../model/auth.model';

export const allUnternehmenNutzerActions = createActionGroup({
    source: 'Benutzerbereich Store',
    events: {
        changeAllUnternehmenNutzer: emptyProps(),
        changeAllUnternehmenNutzerSuccess: props<{ newAllUnternehmenNutzer: AllUnternehmenNutzerPage }>(),
        setTableAction: props<{ newTableAction: TableAction[][] }>(),
        changeSorting: props<{ newSort: SortParameter; isMultiSort: boolean }>(),
        changePage: props<{ newPage: number }>(),
        changeSize: props<{ newSize: number }>(),
        changeSearchParameter: props<{ newSearchParameter: string }>(),
        changeRoleFilter: props<{ newRoleFilter: string[] | null }>(),
        changeActiveFilter: props<{ newActiveFilter: boolean | null }>(),
        changeAllFoerderbereicheFilter: props<{ newAllFoerderbereicheFilter: string | null }>(),
        resetAllUnternehmenNutzerStore: emptyProps(),
        changeUserStatus: props<{ nutzer: AllUnternehmenNutzer; status: boolean }>(),
        updateRemoveUser: props<{ userId: string }>(),
    },
});

export const nutzerRightManagementFoerderantragViewActions = createActionGroup({
    source: 'Rightmanagement Dialog Foerderantrag View',
    events: {
        changeAllFoerderantraege: emptyProps(),
        updateNutzerFoerderantraegeList: emptyProps(),
        setNutzerFoerderantraegeList: props<{ newNutzerFoerderantraege: Foerderantrag[] }>(),
        updateAllFoerderantraege: emptyProps(),
        changeNutzerFoerderbereichList: props<{ nutzerId: string }>(),
        setNutzerFoerderbereichList: props<{ nutzerFoerderbereichList: Foerderbereich[] }>(),
        changeRightId: props<{ rightId: number; isSelected: boolean }>(),
        updateNutzerRights: props<{ nutzerId: string }>(),
        resetRightLists: emptyProps(),
    },
});

export const nutzerRightManagementNutzerViewActions = createActionGroup({
    source: 'Rightmanagement Dialog Nutzer View',
    events: {
        updateFoerderantragNutzerList: props<{ foerderantragId: number }>(),
        setFoerderantragNutzerList: props<{ newFoerderantragNutzerList: NutzerDto[] }>(),
        changeNutzerId: props<{ nutzerId: string; isSelected: boolean }>(),
        updateNutzerBearbeiter: props<{ foerderantragId: number }>(), // TODO: think of better naming
        resetNutzerLists: emptyProps(),
    },
});

export const nutzerRolleManagement = createActionGroup({
    source: 'Rolle Management',
    events: {
        setNutzerData: props<{ newNutzer: AllUnternehmenNutzer }>(),
        changeNewRolle: props<{ newRolle: PortalRole.COMPANY_USER | PortalRole.COMPANY_ADMIN }>(),
        changeRolle: props<{ nutzer: AllUnternehmenNutzer }>(),
        resetRolleManagement: emptyProps(),
    },
});

export const nutzerHinzufuegenActions = createActionGroup({
    source: 'Nutzer Hinzufuegen',
    events: {
        updateNewNutzer: emptyProps(),
        updateNewUserSuccess: emptyProps(),
        setSelectedRolle: props<{ rolle: PortalRole.COMPANY_USER | PortalRole.COMPANY_ADMIN }>(),
        setSelectedEmail: props<{ email: string }>(),
        resetNewNutzer: emptyProps(),
    },
});
