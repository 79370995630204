import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrl: './dialog.component.scss',
})
// TODO FFA-211: Replace with FFA Dialog currently in development in FFA-167
/**
 * The component that displays an dialog
 */
export class DialogComponent {
    @Input() header: string;
    @Input() closeButtonText: string;
    @Input() confirmButtonText: string;
}
