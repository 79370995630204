import { SortParameter } from './store.model';
import { PortalRole } from './auth.model';
import { TableAction } from './data-table.model';
import { Foerderbereich } from './foerderbereich.model';
import { Foerderantrag } from './foerderantraege.model';
import { NutzerDto } from '../service/api/generated';

export type BackendRole = 'FFA_MITARBEITER' | 'FFA_TEAMLEITER' | 'FFA_ADMIN' | 'UNTERNEHMEN_MITARBEITER' | 'UNTERNEHMEN_ADMIN';

export type UnternehmenRolle = PortalRole.COMPANY_USER | PortalRole.COMPANY_ADMIN | PortalRole.COMPANY_SELECT;

export type PutUnternehmenszuordnungReqBody = {
    nutzerIdpId: string;
    rolle: BackendRole;
    aktiv: boolean;
};

export type PostUnternehmenszuordnungReqBody = {
    email: string;
    rolle: BackendRole;
    aktiv: boolean;
};

export type Unternehmenzuordnung = {
    nutzerIdpId: string;
    unternehmenId: number;
    unternehmenName: string;
    rolle: PortalRole;
    active: boolean;
};

export type AllUnternehmenNutzerPage = {
    allUnternehmenNutzer: AllUnternehmenNutzer[];
    totalElements: number;
};

// That's for the return of PortaRoll to the Backend as it expects no white spaces and upercases
export enum NutzerRollen {
    ['FFA Mitarbeiter*in'] = 'FFA_MITARBEITER',
    ['FFA Teamleiter*in'] = 'FFA_TEAMLEITER',
    ['FFA Admin'] = 'FFA_ADMIN',
    ['Mitarbeiter*in'] = 'UNTERNEHMEN_MITARBEITER',
    ['Admin'] = 'UNTERNEHMEN_ADMIN',
    ['Kein Unternehmen ausgewählt'] = 'COMPANY_SELECT',
}

export type AllUnternehmenNutzer = {
    id: string;
    vorname: string;
    nachname: string;
    email: string;
    role: PortalRole;
    status: boolean;
    statusString?: string;
};

export type RightManagementFoerderantrag = {
    nutzerFoerderbereichList: Foerderbereich[];
    nutzerFoerderantraegeList: Foerderantrag[];
    isFoerderbereichRightManagement: boolean;
    newRights: number[];
    removedRights: number[];
    nutzerId: string;
};

export type RightManagementNutzer = {
    foerderantragNutzerList: NutzerDto[];
    newNutzer: string[];
    removedNutzer: string[];
    foerderantragId: number;
};

export type RolleManagement = {
    nutzerId: string;
    rolle: UnternehmenRolle;
    newRolle: UnternehmenRolle;
};

export type NewNutzer = {
    email: string;
    rolle: PortalRole.COMPANY_ADMIN | PortalRole.COMPANY_USER;
};

export type AllUnternehmenNutzerStore = {
    allUnternehmenNutzerTable: TableData<AllUnternehmenNutzer>;
    rightManagementFoerderantrag: RightManagementFoerderantrag;
    rightManagementNutzer: RightManagementNutzer;
    rolleManagement: RolleManagement;
    newNutzer: NewNutzer;
};

export type TableData<T> = {
    data: T[];
    totalElements: number;
    isLoading: boolean;
    sortingArray: SortParameter[];
    size: number;
    page: number;
    searchParameter: string;
    roleFilter: string[] | null;
    activeFilter: boolean | null;
    tableActions: TableAction[][];
    foerderbereichFilter: string | null;
};
