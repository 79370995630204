import { PortalRole } from "../../../../model/auth.model";


export const mapDtoRoleToPortalRole = (dtoRole: 'UNTERNEHMEN_MITARBEITER' | 'UNTERNEHMEN_ADMIN' | 'FFA_MITARBEITER' | 'FFA_TEAMLEITER' | 'FFA_ADMIN'): PortalRole => {
    switch (dtoRole) {
        case 'UNTERNEHMEN_MITARBEITER':
            return PortalRole.COMPANY_USER;
        case 'UNTERNEHMEN_ADMIN':
            return PortalRole.COMPANY_ADMIN;
        case 'FFA_MITARBEITER':
            return PortalRole.FFA_USER;
        case 'FFA_TEAMLEITER':
            return PortalRole.FFA_TEAMLEADER;
        case 'FFA_ADMIN':
            return PortalRole.FFA_ADMIN;
        default:
            throw Error('Invalid Role for Company detected');
    }
};
