import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, exhaustMap, map, catchError, tap, filter, switchMap } from 'rxjs';
import { AuthorizeService } from '../../service/authorize.service';
import { authActions } from './auth.actions';
import { nutzerUnternehmenSelectionActions } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.actions';
import { PortalRole } from '../../model/auth.model';
import { Router } from '@angular/router';
import { PersistenceService } from '../../service/persistence.service';
import { GlobalExceptionHandlerService } from '../../service/api/service/global-exception-handler.service';
import { nutzerProfileActions } from '../nutzer-profile/nutzer-profile.actions';

@Injectable()
export class AuthEffects {
    private action$ = inject(Actions);
    private authService = inject(AuthorizeService);
    private router = inject(Router);
    private persistenceService = inject(PersistenceService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);

    userLoginDetailsEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(authActions.updateLoginDetails),
            exhaustMap((action) =>
                from(this.authService.getUserDetailsFromToken()).pipe(
                    map((details) => {
                        if (action.isLogin) {
                            this.persistenceService.writeToBus(authActions.updateLoginDetails({ isLogin: false }), true)
                        }
                        return authActions.changeLoginDetails(details);
                    }),
                    catchError(() => of(authActions.changeUserToLoggedOut())),
                ),
            ),
        );
    });

    userLoginDetailsWithCompanyEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(authActions.updateLoginDetailsAndCompany),
            exhaustMap((action) =>
                from(this.authService.getUserDetailsFromToken()).pipe(
                    map((details) => {
                        details.role = action.newlySelectedUnternehmen.userRole;
                        return authActions.changeLoginDetailsAndCompanyNoRedirect({ ...details, newlySelectedUnternehmen: action.newlySelectedUnternehmen });
                    }),
                    catchError(() => of(authActions.changeUserToLoggedOut())),
                ),
            ),
        );
    });

    onLoginTriggerUpdateUnternehmenList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(authActions.changeLoginDetails, authActions.changeLoginDetailsAndCompanyNoRedirect),
            filter((details) => {
                if (details.role === null) {
                    return false;
                }
                if ([PortalRole.COMPANY_ADMIN, PortalRole.COMPANY_USER, PortalRole.COMPANY_SELECT].includes(details.role)) {
                    return true;
                }
                this.redirectByRole(details.role);
                return false;
            }),
            exhaustMap(() => of(nutzerUnternehmenSelectionActions.updateNutzerUnternehmenSelectionList())),
        );
    });


    postLogoutUserEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(authActions.postChangeUserToLoggedOut),
            exhaustMap(() => {
                this.persistenceService.writeToBus(authActions.changeUserToLoggedOut(), true)
                return of(authActions.changeUserToLoggedOut());
            }),
        );
    })

    logoutUserEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(authActions.changeUserToLoggedOut),
            tap(() => {
                this.router.navigate(['/start']);
                this.authService.logoutUser();
            }),
            exhaustMap(() => of(nutzerUnternehmenSelectionActions.setInitialState())),
        )
    });

    changeUserRoleEffect$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(authActions.changeUserRole),
                tap((roleData) => {
                    this.redirectByRole(roleData.role);
                }),
            )
        },
        { dispatch: false },
    );

    userTokenEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(authActions.updateToken),
            exhaustMap(() =>
                from(this.authService.getAccessToken()).pipe(
                    map((token: string | null) => authActions.setToken({ token: token })),
                    catchError(() => of(authActions.setToken({ token: null }))),
                ),
            ),
        );
    });

    refreshTokenEffect$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(authActions.updateRefreshToken),
                tap(() => {
                    this.authService.refreshToken();
                }),
            )
        },
        { dispatch: false },
    );

    updatePasswordChange$ = createEffect(() => {
        return this.action$.pipe(
            ofType(authActions.updatePasswordChange),
            switchMap((action) =>
                this.authService.updatePassword({ oldPassword: action.oldPassword, newPassword: action.newPassword })
                    .then(() => {
                        return nutzerProfileActions.setNutzerProfilePasswordChangeSuccess();
                    }).catch((error) => {
                        const newError = { name: error.name, status: error.status, message: '' };
                        if (error.name === 'LimitExceededException') {
                            newError.message = 'Zu viele Versuche, versuchen sie es später erneut.';
                        } else if (error.name === 'NotAuthorizedException') {
                            newError.message = 'Das alte Passwort ist ungültig.';
                        }
                        this.globalExceptionHandlerService.handleAWSError(newError);
                        return nutzerProfileActions.setNutzerProfilePasswordChangeFailed({ error: newError.message });
                    })

            ),
        );
    });

    private redirectByRole(role: PortalRole | null): void {
        switch (role) {
            case null:
                break;
            case PortalRole.COMPANY_SELECT:
                break;
            case PortalRole.COMPANY_ADMIN:
                this.router.navigate(['/unternehmensverwaltung']);
                break;
            case PortalRole.FFA_ADMIN:
                this.router.navigate(['/ffaverwaltung']);
                break;
            default:
                this.router.navigate(['/alle-antraege']);
                break;
        }
    }
}
