import { Injectable, inject } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { selectFormularStoreActiveFormularAntragsnummer } from "../store/formular/formular.selectors";
import { first } from "rxjs";
import { Router, UrlSerializer } from "@angular/router";
import { PersistenceService } from "./persistence.service";
import { formularActionsInt } from "../store/formular/formular.actions";

@Injectable({
    providedIn: 'root'
})
export class FormularHandlerService {
    private store = inject(Store);
    private router = inject(Router);
    private urlSerializer = inject(UrlSerializer);
    private persistenceService = inject(PersistenceService);

    private antragsnummer: string;

    /**
     * creates a JSON file from the given JSON of the form
     * @param fileContentJSON the JSON from which the file should be created
     * @returns the JSON file
     */
    createJsonFile(fileContentJSON: string): File {
        this.store.select(selectFormularStoreActiveFormularAntragsnummer).pipe(first()).subscribe((antragsnummer) => {
            this.antragsnummer = antragsnummer;
        });
        const fileContent = new Blob([fileContentJSON], { type: 'application/json' });
        const fileName = this.antragsnummer + '.json';
        return new File([fileContent], fileName, { type: 'application/json' });
    }

    /**
     * clears the formular actions in the bus
     */
    clearFormularActionsInBus(): void {
        this.persistenceService.removeFromBus(formularActionsInt.setUpNewFormular.type);
        this.persistenceService.removeFromBus(formularActionsInt.setActiveAntragsnummer.type);
        this.persistenceService.removeFromBus(formularActionsInt.setExistingFormular.type);
        this.persistenceService.removeFromBus(formularActionsInt.changeCreateUnternehmenSuccess.type);
    }

    /**
     * navigates to the formular with the given params
     * @param params the params the url should be called with
     */
    navigateToFormular(params: Record<string, string>): void {
        const tree = this.router.createUrlTree(['/formular'], { queryParams: params });
        const url = this.urlSerializer.serialize(tree);
        window.open(url, '_blank');

    }

    /**
     * triggers the given action since the actions are created inside an effect and there direct triggering of actions is not allowed or wished for
     * @param action the action to be triggered
     */
    triggerAction(action: Action): void {
        this.store.dispatch(action);
    }

    /**
     * Post a success message back to the message port given for the upload
     * @param messagePort The MessageChanel port that was send with the upload request
     * @param success the status if the upload was successfull or not
     */
    handleReturnMessage(messagePort: MessagePort, success: boolean): void {
        messagePort.postMessage({ success });
    }
}