import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
    name: 'safePostkorbNachrichtenHtml'
})
export class SafePostkorbNachrichtenHtmlPipe implements PipeTransform {
    private domSanitzer = inject(DomSanitizer);

    /**
     * The pipe is used to transform the html string of the Postkorbnachricht to a trusted html string.
     * It also will replace the line breaks with html line breaks (<br>) that will be correctly interpreted with the innerHtml injection
     * @param html The html string to be transformed
     * @returns The trusted html string
     */
    transform(html: string): SafeHtml {
        const withLineBreaks = html.replace(/\r?\n/g, '<br>');
        return this.domSanitzer.sanitize(1, withLineBreaks) || '';
    }
}