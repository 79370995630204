<div class="add-new-benutzer">
    <div class="add-new-benutzer__container">

      <form>
        <mat-form-field class="add-new-benutzer__container__email-input">
          <mat-label>Email</mat-label>
          <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                 placeholder="user@example.com">
          @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
            <mat-error>Please enter a valid email address</mat-error>
          }
          @if (emailFormControl.hasError('required')) {
            <mat-error>Email is <strong>required</strong></mat-error>
          }
        </mat-form-field>
      </form>

        <div class="add-new-benutzer__container__buttons-row">
            <mat-button-toggle-group class="add-new-benutzer__container__buttons-row" name="" aria-label="" (change)="onToggleChange($event)">
              <mat-button-toggle class="add-new-benutzer__container__buttons-row__button--second" [value]="PortalRole.COMPANY_ADMIN">Als Admin hinzufügen</mat-button-toggle>
              <mat-button-toggle class="add-new-benutzer__container__buttons-row__button--third" [value]="PortalRole.COMPANY_USER">Als Mitarbeiter*in hinzufügen</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</div>


