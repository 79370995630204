export interface ErrorDefaultInput {
    status: number;
    instance: string;
    title: ErrorTitleNames;
    detail?: string;
    possibleSolutionServerSend?: string[];
    error: Error;
}

export interface ErrorDefaultValues {
    shouldLogDirectly: boolean;
    possibleSolution?: string;
}

export interface ErrorInformation extends ErrorDefaultInput, ErrorDefaultValues {
}

export type LogMessage = {
    message: string;
    level: LogLevel;
    timestamp: string;
}

export type ErrorTitleNames = '400 Bad Request'
    | '401 Authentifizierungsfehler'
    | '403 Keine Rechte'
    | '404 Eintrag nicht gefunden'
    | '408 Timeout'
    | '500 Interner Server Fehler'
    | 'Fehler beim Hochladen der Datei'
    | 'Fehler in der Formularkommunikation'
    | 'Fehler in der Authentifizierung'
    | 'Unbekannter Fehler'
    | 'Kein Fehler';

export enum LogLevel {
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
    DEBUG = 'debug',
    TRACE = 'trace',
}

export type LogLevelServer = "INFO"
    | "WARN"
    | "ERROR"
    | "DEBUG"
    | "TRACE";

export const ErrorTitle: Record<number, ErrorTitleNames> = {
    400: '400 Bad Request',
    401: '401 Authentifizierungsfehler',
    403: '403 Keine Rechte',
    404: '404 Eintrag nicht gefunden',
    408: '408 Timeout',
    500: '500 Interner Server Fehler',
    1: 'Fehler in der Formularkommunikation',
    2: 'Fehler beim Hochladen der Datei',
    3: 'Fehler in der Authentifizierung',
    '-1': 'Unbekannter Fehler',
    0: 'Kein Fehler',
}

export const NOERROR: ErrorInformation = {
    status: 0,
    detail: '',
    shouldLogDirectly: false,
    instance: "",
    title: ErrorTitle[0],
    error: new Error('Kein Fehler'),
}

export type ErrorStore = {
    errorHistory: ErrorInformation[];
    logList: LogMessage[];
    logTimer: number;
    hasError: boolean;
    shownError: ErrorInformation;
}